import React from 'react';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import Message from './message';
import { formRow, formControls, submit } from './ContactForm.module.css';

const initialState = {
  formState: null,
  formError: null,
  name: '',
  email: '',
  message: ''
};

const ContactForm = () => {
  const [state, setState] = React.useState(initialState);

  const onFieldChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    setState({
      ...state,
      [name]: value,
      formState: null,
      formError: null
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setState({
      ...state,
      formState: 'SENDING'
    });

    const { name, email, message } = state;
    const form = {
      name,
      email,
      message
    };
    const config = {};

    axios.post('https://bf03h7kpcl.execute-api.us-east-1.amazonaws.com/prod/contact', form, config).then(() => {
      setState({
        ...initialState,
        formState: 'SUBMITTED'
      });
    }).catch((err) => {
      console.error('POST Error: ', err.response.data);
      setState({
        formState: 'ERROR',
        formError: JSON.stringify(err.response.data)
      });
    });
  };

  const { formState } = state;
  const errorMessage = 'Oops! Something went wrong. Please ensure all fields are filled try again.';
  const successMessage = 'Thanks! I look forward to speaking to you.';

  return (
    <form onSubmit={onSubmit}>
      {formState === 'ERROR' && <Message type="ERROR" message={errorMessage} />}
      {formState === 'SUBMITTED' && <Message type="SUCCESS" message={successMessage} />}

      <div className={formRow}>
        <TextField
          id="contactName"
          name="name"
          label="Name"
          variant="outlined"
          value={state.name}
          onChange={onFieldChange}
          disabled={formState === 'SENDING'}
          required
          fullWidth
        />
      </div>
      <div className={formRow}>
        <TextField
          id="contactName"
          name="email"
          label="Email"
          variant="outlined"
          type="email"
          value={state.email}
          onChange={onFieldChange}
          disabled={formState === 'SENDING'}
          required
          fullWidth
        />
      </div>
      <div className={formRow}>
        <TextField
          id="contactMessage"
          name="message"
          label="Message"
          variant="outlined"
          value={state.message}
          onChange={onFieldChange}
          disabled={formState === 'SENDING'}
          required
          fullWidth
          multiline
          rows={8}
        />
      </div>
      <div className={formControls}>
        <input
          type="submit"
          className={submit}
          value={formState === 'SENDING' ? 'Sending...' : 'Send'}
          disabled={formState === 'SENDING'}
        />
      </div>
    </form>
  );
};

export default ContactForm;
