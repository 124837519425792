import PropTypes from 'prop-types';
import React from 'react';
import { spacer } from './HorizontalList.module.css';

const HorizontalList = ({ items }) => (
  <>
    {items.map((item, index) => (
      <>
        <span key={item}>{` ${item} `}</span>

        {index < items.length - 1 && (
          <span className={spacer}>|</span>
        )}
      </>
    ))}
  </>
);

HorizontalList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string)
};

HorizontalList.defaultProps = {
  items: []
};

export default HorizontalList;
