import React from 'react';

import InfoIcon from '@material-ui/icons/Info';
import CodeIcon from '@material-ui/icons/Code';
import ChatIcon from '@material-ui/icons/Chat';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import ContactForm from '../components/ContactForm';
import HorizontalList from '../components/HorizontalList';
import ContentSection from '../components/ContentSection';
import Hero from '../components/Hero';

const IndexPage = () => {
  const data = {
    about: {
      heading: 'About',
      text: [
        'I am a software developer with over 10 years experience and a passion for efficiency, usability, and good design. I take pride in developing clean, maintainable solutions that are loved by users. I\'m just as happy to geek out about performance optimizations as I am about CSS tricks.',
        'When I\'m not writing code, I am usually at a race track or rally stage torturing tires. I am the overall MLRC Rallycross champion (2016), have won my class at the Canadian Autoslalom Nationals (2018), and occasionally coach advanced driving.'
      ]
    },
    techSkills: {
      heading: 'Tech Skills',
      text: [
        'While my current focus is front-end web development, I have full stack experience. Some technologies I have worked with include:'
      ],
      list: [
        ['Javascript', 'ES6', 'Typescript'],
        ['React', 'React Native', 'Redux', 'Gatsby', 'Angular'],
        ['SASS', 'Responsive Design', 'CSS Modules'],
        ['Cloud Architecture', 'Amazon AWS', 'Google Firebase'],
        ['NodeJS', 'AWS Lambda', 'Google Cloud Functions'],
        ['DynamoDB', 'Firebase Realtime DB', 'Cloud Firestore']
      ]
    },
    contact: {
      heading: 'Let\'s Work Together',
      text: [
        'If you\'d like to chat about an interesting project I can help with, please feel free to drop me a line.'
      ]
    }
  };

  return (
    <Layout>
      <SEO title="Home" />

      <Hero />

      <ContentSection heading={data.about.heading} anchor="about" IconTag={InfoIcon}>
        {data.about.text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </ContentSection>

      <ContentSection heading={data.techSkills.heading} anchor="techSkills" variant="dark" IconTag={CodeIcon}>
        {data.techSkills.text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <ul>
          {data.techSkills.list.map((row) => (
            <li key={JSON.stringify(row)}>
              <HorizontalList items={row} />
            </li>
          ))}
        </ul>
      </ContentSection>

      <ContentSection heading={data.contact.heading} anchor="contact" IconTag={ChatIcon}>
        {data.contact.text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <ContactForm />
      </ContentSection>
    </Layout>
  );
};

export default IndexPage;
