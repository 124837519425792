import PropTypes from 'prop-types';
import React from 'react';
import './message.css';

const Message = ({ type, message }) => {
  const className = `message-${type.toLowerCase()}`;
  return (
    <p className={`message ${className}`}>{message}</p>
  );
};

Message.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
};

Message.defaultProps = {
  type: 'SUCCESS',
  message: ''
};

export default Message;
