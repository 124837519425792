import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  container,
  statement,
  icon,
  hoverBounce
} from './Hero.module.css';

const Hero = () => (
  <section className={container} id="home">
    <div>
      <h1>Alex Rechetov</h1>
      <p>
        <span className={statement}>Front-end Developer</span>
        <span className={statement}>
          <a href="https://goo.gl/maps/tkYdgj6JZn76EV7HA" target="_blank" rel="noopener noreferrer">
            <LocationOnIcon className={`${icon} ${hoverBounce}`} width="24" height="24" />
            Toronto, Canada
          </a>
        </span>
      </p>
    </div>
  </section>
);

export default Hero;
