import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import {
  container,
  dark,
  backgroundIcon,
  textContainer
} from './ContentSection.module.css';

const ContentSection = ({
  IconTag,
  anchor,
  heading,
  variant,
  children
}) => {
  const { ref, inView } = useInView({
    threshold: 0.3
  });

  return (
    <section
      className={`${container} ${(variant === 'dark' ? dark : '')}`}
      id={anchor}
      ref={ref}
    >
      <IconTag className={backgroundIcon} />
      <div
        className={textContainer}
        style={{
          transition: 'opacity 300ms',
          opacity: inView ? 1 : 0,
        }}
      >
        <h2>{heading}</h2>
        {children}
      </div>
    </section>
  );
};

ContentSection.propTypes = {
  IconTag: PropTypes.shape({}).isRequired,
  anchor: PropTypes.string,
  heading: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['dark']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

ContentSection.defaultProps = {
  anchor: null,
  variant: null,
  children: null
};

export default ContentSection;
